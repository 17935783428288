<template>
    <div class="auth-page-wrapper pt-5">
            <div class="auth-one-bg-position auth-one-bg"  id="auth-particles">
                <div class="bg-overlay"></div>
            </div>

            <div class="auth-page-content">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="text-center mt-sm-5 mb-4 text-white-50">
                                <p class="mt-3 fs-15 fw-medium" style="font-size:25px!important;color:white;">{{ $t('bestCRM') }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="row justify-content-center">
                        <div class="col-md-8 col-lg-6 col-xl-5">
                            <div class="card mt-4">
                            
                                <div class="card-body p-4"> 
                                    <div class="text-center mt-2">
                                        <h5 class="text-primary">{{ $t('LockScreen') }}</h5>
                                        <p class="text-muted">{{ $t('enterPasswordUnlock') }}</p>
                                    </div>
                                    <div class="user-thumb text-center">
                                        <!-- <img src="@/assets/images/users/avatar-1.jpg" class="rounded-circle img-thumbnail avatar-lg shadow" alt="thumbnail"> -->
                                        <div class="avatar-xl" style="margin: 0 auto;">
                                            <div class="avatar-title rounded bg-soft-success text-success">
                                                {{getInitials(this.user.name)}}
                                            </div>
                                        </div>
                                        <h5 class="font-size-15 mt-3">{{ this.user.name }}</h5>
                                    </div>
                                    <div class="p-2 mt-4">
                                            <div class="mb-3">
                                                <label class="form-label" for="userpassword"><b>{{ $t('pincode') }}</b></label>
                                                <input type="password" class="form-control" v-model="pin" id="userpassword" :placeholder="$t('enterPin')" required>
                                            </div>
                                            <div class="mb-2 mt-4">
                                                <button class="btn btn-success w-100" @click="unlock">{{ $t('Unlock') }}</button>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <footer class="footer">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="text-center">
                                <p class="mb-0 text-muted">&copy; {{new Date().getFullYear()}} SkyService <i class="mdi mdi-heart text-danger"></i></p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
</template>

<script>
import { storeS } from '@/store'

export default {
    data(){
        return {
            pin:""
        }
    },
    methods: {
        getInitials(string) {
            var names = string.split(' '),
                initials = names[0].substring(0, 1).toUpperCase();
                
                if (names.length > 1) {
                    initials += names[names.length - 1].substring(0, 1).toUpperCase();
                }
                return initials;
        },
        unlock(){
            if(this.pin == this.user.pin){
                this.$router.push('/')
            }else{
                this.$toast.error(`Пин-код не правильный`);
            }
        }
    },
    computed:{
        perms() {
            return storeS.perms
        },
        user() {
            return storeS.userbase
        } 
    }
}
</script>

<style>
.auth-one-bg .bg-overlay {
    background: #46a049;
    opacity: 1;
}
.user-thumb .avatar-title {
  font-size: 30px;
  font-weight: 600;
}
</style>